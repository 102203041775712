import React, { useContext, useState, useEffect } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";

import { useAuth } from "./AuthContext";

const SessionContext = React.createContext();

export function useSession() {
	return useContext(SessionContext);
}

export function SessionProvider({ children }) {
	SessionProvider.propTypes = {
		children: PropTypes.object,
	};
	const [loading] = useState(false);
	const [sessionId, setSessionId] = useState("");
	const [randomName, setRandomName] = useState("");
	const [matchedUser, setMatchedUser] = useState("");
	const { userId } = useAuth();

	useEffect(() => {
		setSessionId(localStorage.getItem("sessionId"));
	}, []);

	function updateSession(payload) {
		setSessionId(payload.id);
	}

	function updateRandomName(payload) {
		setRandomName(payload.name);
	}

	function updateMatchedUserName(payload) {
		const { myName, partnerName } = payload.names;
		setRandomName(myName);
		setMatchedUser(partnerName);
	}

	// async function getSessionIdFromAPI() {
	// 	var token = localStorage.getItem("access-token");
	// 	try {
	// 		const sessionData = await axios.get(
	// 			`${process.env.REACT_APP_BASE_SERVER_URL}/api/dashboard/checksession`,
	// 			{
	// 				headers: {
	// 					Authorization: token,
	// 				},
	// 			}
	// 		);

	// 		if (sessionData.data.data.results.length > 0) {
	// 			let newSessionId = sessionData.data.data.results[0].sessionId;
	// 			updateSession({ id: newSessionId });
	// 		} else {
	// 			alert("No Ongoing Sessions ");
	// 			updateSession({ id: null });
	// 		}
	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// }

	const value = {
		updateSession,
		sessionId,
		randomName,
		updateRandomName,
		updateMatchedUserName,
		matchedUser,
		setSessionId,
	};

	return (
		<SessionContext.Provider value={value}>
			{loading === false && children}
		</SessionContext.Provider>
	);
}
