import React, { useState, useEffect } from "react";

import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton } from "@mui/material";
import axios from "axios";
import Wave from "wave-visualizer";

import { serverLog } from "../../App";
import { useSession } from "../../context/SessionContext";
import { playAudioFromBase64 } from "../../utils/speak";
import {
	vibratoVoiceFilter,
	deepVoiceFilter,
	heliumVoiceFilter,
	chipmunkVoiceFilter,
} from "../../voiceFilters/voiceFilter.js";
import MicDeviceSelection from "../MicDeviceSelection/MicDeviceSelection";
import Recorder from "../Recorder/Recorder.jsx";

import "./PreRoomPage.css";

const PreRoomPage = ({ next, randomName }) => {
	const [wave, setWave] = useState(null);
	const [filter, setFilter] = useState("Helium");
	const [mediaStream, setMediaStream] = useState(null);
	const [isReadyToGo, setIsReadyToGo] = useState(false);
	const [streamDestination, setStreamDestination] = useState(null);
	const [selectedDevice, setSelectedDevice] = useState("");
	const [startTime] = useState(new Date());
	const [selectOpen, setSelectOpen] = useState(false);
	const [isRecording, setIsRecording] = useState(false);
	const { sessionId } = useSession();
	let preRoomTextCarla = [
		`Let’s explore how you’ll sound.`,
		`<speak>Hit <sub alias="Reecord">Record</sub>, then say:</speak>`,
		`“Lookie, lookie! Let’s get ready to boogie!”`,
	];

	
	useEffect(() => {
		let init = async () => {
			audioTest(filter, selectedDevice);
			for (let carlaText of preRoomTextCarla) {
				await playAudioFromBase64(carlaText);
			}
		};

		init();
	}, []);

	const handleChange = (e) => {
		stopAudioTest();
		setFilter(e.target.value);
		audioTest(e.target.value, selectedDevice);

		serverLog(sessionId, "dashboard_change_filter", `Changed to ${e.target.value}`);
	};

	const handleDeviceChange = (e) => {
		stopAudioTest();
		setSelectedDevice(e.target.value);
		audioTest(filter, e.target.value);
	};

	const audioTest = (filterx, requiredDevice) => {
		navigator.mediaDevices
			.getUserMedia({
				audio: { deviceId: requiredDevice },
			})
			.then(function (micStream) {
				setMediaStream(micStream);
				let newWave = new Wave();
				setWave(newWave);

				const usedDevice = micStream
					.getTracks()
					.map((track) => track.getSettings().deviceId)[0];
				setSelectedDevice(usedDevice);
				localStorage.setItem("audio-device", usedDevice);

				var ctx = new AudioContext();
				var destination = ctx.createMediaStreamDestination();
				var mic = ctx.createMediaStreamSource(micStream);

				switch (filterx) {
					case "None":
						mic.connect(destination);
						break;
					case "Vibrato":
						vibratoVoiceFilter({ ctx, mic, destination });
						break;	
					case "Deep":
						deepVoiceFilter({ ctx, mic, destination });
						break;
					case "Helium":
						heliumVoiceFilter({ ctx, mic, destination });
						break;
					/*					case "Chipmunk":
						mic.open(micStream.id);
						chipmunkVoiceFilter({ ctx, mic, destination });
						break;
*/
					default:
						mic.connect(destination);
						break;
				}
				newWave.fromStream(destination.stream, "output", {
					colors: ["white"],
				});
				setStreamDestination(destination);
			})
			.catch(function (err) {
				console.log(err.message);
			});
	};
	const stopAudioTest = () => {
		wave?.stopStream();
		mediaStream.getAudioTracks().forEach(function (track) {
			track.stop();
		});
	};

	const postDuration = async (duration) => {
		let token = localStorage.getItem("access-token");
		await axios
			.post(
				`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/micduration`,
				{
					sessionId,
					duration,
				},
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((res) => {
				console.log(res);
			})
			.catch((e) => console.log(e));
	};

	return (
		<div className="sound-test">
			<div className="dashboard-header">
				<div className="dashboard-display-name">
					<img src="/assets/avatar.png"></img>
					{randomName}
				</div>
			</div>
			<div className="pre-room-welcome-text">
				Let’s explore how you’ll sound.
			</div>
			<div className="pre-room-welcome-text">Hit Record, then say:</div>
			<div className="pre-room-welcome-text">
				“Lookie, lookie! Let’s get ready to boogie!”
			</div>

			<div className="pre-room-audio-test-canvas">
				<canvas id="output" height="100" width="100"></canvas>
			</div>

			<div className="pre-room-audio-test-voice-select">
				<div className="options">
					<label>Voice: </label>
					<Select
						disabled={isRecording}
						color="secondary"
						variant="outlined"
						value={filter}
						style={{
							color: "white",
							borderColor: "transparent",
						}}
						onChange={(e) => handleChange(e)}
					>
						<MenuItem value={"None"}><em>None</em></MenuItem>
						<MenuItem value={"Helium"}>Helium</MenuItem>
						<MenuItem value={"Deep"}>Deep</MenuItem>
						<MenuItem value={"Vibrato"}>Wobbly</MenuItem>
					</Select>
				</div>
			</div>

			<div className="recorded-voice-container">
				{streamDestination && (
					<Recorder
						stream={streamDestination}
						isReadyToGo={setIsReadyToGo}
						isRecording={isRecording}
						setIsRecording={setIsRecording}
					/>
				)}
			</div>

			{(isReadyToGo ||
				(MediaRecorder
					? MediaRecorder.isTypeSupported
						? !MediaRecorder.isTypeSupported("audio/webm")
						: true
					: true)) && (
				<Button
					onClick={() => {
						let duration =
							(new Date().getTime() - startTime.getTime()) / 1000;
						// console.log(duration);
						postDuration(duration).then(() => {
							next((n) => ++n);
						});
					}}
					variant="contained"
					color="secondary"
				>
					Sounds Dandy
				</Button>
			)}
			<div
				style={{
					color: "white",
					position: "fixed",
					right: "0px",
					top: "0px",
					opacity: isRecording ? "0.5" : "1",
				}}
				className="mic-change"
				onClick={() => {
					if (!isRecording) {
						setSelectOpen(true);
					}
				}}
			>
				<SettingsIcon />
			</div>
			<MicDeviceSelection
				open={selectOpen}
				closeDialog={() => {
					setSelectOpen(false);
					let element = { target: { value: filter } };
					handleChange(element);
				}}
				selectedDevice={selectedDevice}
				setSelectedDevice={setSelectedDevice}
			></MicDeviceSelection>
		</div>
	);
};

export default PreRoomPage;
