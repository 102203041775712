import React, { useEffect, useState, useRef } from "react";

import "./FeedbackScreens.css";
import { Button, RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Rating, TextField } from "@mui/material";
import axios from "axios";

import { useSession } from "../../context/SessionContext";
import { playAudioFromBase64 } from "../../utils/speak";

const useStyles = makeStyles({
	root: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			borderColor: "white",
		},
		"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			borderColor: "darkgray",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
			{
				borderColor: "white",
			},
		"& .MuiOutlinedInput-input": {
			color: "white",
		},
		"&:hover .MuiOutlinedInput-input": {
			color: "darkgray",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
			color: "white",
		},
		"& .MuiInputLabel-outlined": {
			color: "white",
		},
		"&:hover .MuiInputLabel-outlined": {
			color: "darkgray",
		},
		"& .MuiInputLabel-outlined.Mui-focused": {
			color: "white",
		},
	},
});

export default function FeedbackScreens() {
	const [idx, setIdx] = useState(0);
	const [rating, setRating] = useState(null);
	const [text, setText] = useState();
	const [choice, setChoice] = useState("no");
	const { matchedUser, sessionId } = useSession();

	useEffect(() => {
		if (!process.env.REACT_APP_DISABLE_RECORDING) {
			axios
				.post(
					`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/stoprecording`,
					{
						roomId: localStorage.getItem("roomId"),
					},
					{
						headers: {
							Authorization: localStorage.getItem("access-token"),
						},
					}
				)
				.then((res) => {
					console.log(res);
				}).catch((err) => {console.log(err);});
			console.log("stopping recording");
		}
	}, []);

	useEffect(() => {
		const token = localStorage.getItem("access-token");
		axios.post(
			`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/postfeedback`,
			{
				sessionId,
				rating,
				text,
				meet: choice,
			},
			{
				headers: {
					Authorization: token,
				},
			}
		);
		console.log("Feedback Submitted");
	}, [rating, text, choice]);

	const componentArray = [
		{ component: Start },
		{
			component: FeedbackRating,
			props: { rating, setRating, text, setText },
		},
		{
			component: YesNo,
			props: { choice, setChoice, partnerName: matchedUser },
		},
		{ component: TheEnd },
	];

	const MyComponent = componentArray[idx];
	return (
		<div className="feedback-page-container">
			<Paper className="feedback-screen">
				<MyComponent.component {...MyComponent.props} number={idx} />
				{idx < componentArray.length - 1 && (
					<div className={idx < componentArray.length - 2 ? "feedback-controls" : "feedback-submit"}>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								var lastScreen = (idx == componentArray.length - 2);
								console.log("lastScreen ", lastScreen);
								if(lastScreen) {
									console.log("yes");
									setChoice("yes");
								}

								setIdx(idx + 1);

								if(lastScreen) {
									const meetingLink = "https://meet.google.com/vwt-mnyw-jti";
									window.open(meetingLink, '_blank');
								}
							}}
						>
							{idx < componentArray.length - 2
								? "Next"
								: "Take me to the Google Meet"}
						</Button>
					</div>
				)}
				<div className="left-point" />
				<div className="carla-box">
					<img src="/assets/carla.gif" alt="carla" />
				</div>
			</Paper>
		</div>
	);
}

function Start() {
	const [showVideo, setShowVideo] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setShowVideo(false);
			playAudioFromBase64(
				"Thanks for participating so far!! Quick question before choosing to reveal your identity to your partner."
			);
		}, 5000);
	}, []);

	return (
		<div>
			{showVideo && (
				<div
					style={{
						position: "fixed",
						zIndex: "1000",
						height: "100%",
						width: "100%",
						top: "0",
						left: "0",
						display: "grid",
						placeItems: "center",
					}}
				>
					<video
						loop
						muted
						disablePictureInPicture
						autoPlay
						style={{
							height: "100%",
							width: "100%",
							backgroundColor: "black",
						}}
					>
						<source
							src="/assets/background-stars.mp4"
							type="video/mp4"
						/>
					</video>
					<h1
						style={{
							position: "absolute",
							left: "50%",
							top: "50%",
							transform: "translate(-50%, -50%)",
							textAlign: "center",
						}}
					>
						Returning to earth <br /> momentarily...
					</h1>
				</div>
			)}
			<div>
			Thanks for participating so far!
			<br /><br />
			Quick question before choosing to reveal your identity to your partner.
			</div>
		</div>
	);
}

function FeedbackRating({ rating, setRating, number, text, setText }) {
	useEffect(() => {
		playAudioFromBase64("What level of connection are you feeling right now?");
	}, []);

	const classes = useStyles();
	return (
		<div>
			<div>{number}. What level of connection are you feeling right now?</div>
			<div className="feedback-rating-stars">
				<Rating
					size="large"
					name="simple-controlled"
					value={rating}
					onChange={(event, newValue) => {
						setRating(newValue);
					}}
				/>
			</div>
			<div className="feedback-text">
				<TextField
					className={classes.root}
					variant="outlined"
					id="outlined-multiline-static"
					rows={4}
					label={
						rating <= 3
							? "What was missing for you?"
							: "What was most connecting for you?"
					}
					multiline
					fullWidth
					placeholder="Please share what drove your choice…"
					onChange={(event) => {
						setText(event.target.value);
					}}
				/>
			</div>
		</div>
	);
}

function YesNo({ choice, setChoice, number, partnerName }) {
	useEffect(() => {
		playAudioFromBase64(
			`Would you like to reveal your earth identity to ${
				partnerName ? partnerName : "your partner"
			}?`
		);
	}, []);

	return (
		<div>
			<div>
				{number}. Would you like to reveal your earth identity to {" "}
				{partnerName ? partnerName : "your partner"}?
			</div>
			<div className="feedback-rating-stars">
			</div>
		</div>
	);
}
function TheEnd() {
	useEffect(() => {
		playAudioFromBase64(
			`All right! Be well, my friend. Hope to see you back in the Multiverse, soon!`
		);
	}, []);

	return (
		<div>
			<div>
				All right! Be well, my friend. Hope to see you back in the
				Multiverse, soon!
			</div>
		</div>
	);
}
