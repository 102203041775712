import React, { useEffect, useState, useRef } from "react";

import { Button } from "@material-ui/core";
import { Paper } from "@mui/material";
import axios from "axios";
import { useHistory } from "react-router";
import { log } from "tone/build/esm/core/util/Debug";

import { useSession } from "../../context/SessionContext";
import { playAudioFromBase64 } from "../../utils/speak";
import "./Dashboard.css";
import Loading from "../Loading/Loading.jsx";
import PreRoomPage from "../PreLoginPage/PreRoomPage.jsx";

function getBaseComponents(randomName) {
	const componentArray = [
		{
			component: TextSpeakFirst,
			props: {
				heading: `Welcome to the Multiverse!`,
				speak: `My name is Carla and I’ll be your host for today.`,
				playDrone: true,
			},
		},
		{
			component: TextSpeak,
			props: {
				speak: `Today, you will be paired in a fun 1-on-1 conversation with a new person from the Multiverse.
				Get ready to expand your own personal universe in the process.`,
			},
		},
		{
			component: TextSpeakWithNewName,
			props: {
				speak: `You will now be granted your name for the session - ${randomName}. This is the name of a major constellation. Because, you know, we are all made of stars.`,
				randomName: randomName,
				playDrone: true,
			},
		},
		{
			component: TextSpeak,
			props: {
				speak: `You will also be granted a new voice. The intention for this is to ensure a level of anonymity so that you can be playful and silly with the other person – without having to worry about how you think you sound to them.`,
				randomName: randomName,
				playDrone: true,
			},
		},
		{
			component: PreRoomPage,
			props: {
				randomName: randomName,
			},
		},
		{
			component: TextSpeak,
			props: {
				speak: "Okay, let’s start the session by taking a few deep breaths so that we can relax. Follow my count...",
				playDrone: true,
				randomName: randomName,
			},
		},
		{
			component: VideoPlay,
			props: {
				playDrone: true,
			},
		},
	];
	return componentArray.map((component, idx) => {
		component["id"] = `component-${idx}`;
		return component;
	});
}

export default function Dashboard() {
	const [idx, setIdx] = useState(0);
	const [answers, setAnswers] = useState([]);
	const { updateRandomName, sessionId, randomName } = useSession();
	const [componentArray, setComponentArray] = useState(null);
	const [startTime, setStartTime] = useState(null);
	const [token] = useState(localStorage.getItem("access-token"));
	const history = useHistory();
	const droneAudio = useRef();

	useEffect(() => {
		async function init() {
			let assignName = await axios.post(
				`${process.env.REACT_APP_BASE_SERVER_URL}/api/dashboard/assignName`,
				{ sessionId },
				{
					headers: {
						Authorization: token,
					},
				}
			);
			updateRandomName({ name: assignName.data.data.assignedName });

			//
			// Build the actual components
			//
			const componentArray = getBaseComponents(
				assignName.data.data.assignedName
			);
			await axios
				.get(
					`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/getquestions`,
					{
						headers: {
							Authorization: token,
						},
						params: { sessionId },
					}
				)
				.then((res) => {
					const questions = res.data.data.questions;
					if (questions.length > 0) {
						componentArray.push({
							component: TextSpeak,
							props: {
								speak: `I have a few questions for you before we begin.`,
								randomName: assignName.data.data.assignedName,
							},
						});
						questions.forEach((q) => {
							componentArray.push({
								component: QuestionPlay,
								id: q.id,
								props: {
									question: q,
									sessionId,
									setAnswers,
									randomName:
										assignName.data.data.assignedName,
									startTime,
									setStartTime,
								},
							});
						});
					}
					//console.log(componentArray);
				})
				.catch((e) => console.log(e))
				.finally(() => {
					setComponentArray(componentArray);
					if (componentArray.length === 0) {
						history.push("/room-waiting");
					}
				});
		}
		init();
	}, []);

	const MyComponent = componentArray ? componentArray[idx] : null;
	useEffect(() => {
		if (idx > componentArray?.length - 1) {
			if (answers.length > 0) {
				axios
					.post(
						`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/postanswers`,
						{
							answers,
							duration:
								(new Date().getTime() - startTime.getTime()) /
								1000,
						},
						{
							headers: {
								Authorization: token,
							},
						}
					)
					.catch((e) => console.log(e))
					.finally(() => {
						history.push("/room-waiting");
					});
			} else {
				history.push("/room-waiting");
			}
		}
	}, [idx]);

	return (
		<div
			className="dashboard-page-container"
			onClick={() => {
				droneAudio.current?.play();
			}}
		>
			{MyComponent ? (
				<Paper className="dashboard-screen">
					<MyComponent.component
						{...MyComponent.props}
						number={idx}
						next={setIdx}
						key={MyComponent.id}
					/>
					<audio
						style={{
							display: "none",
						}}
						loop
						ref={droneAudio}
					>
						<source src="/assets/drone.mp3" type="audio/mp3" />
					</audio>
					<div className="left-point" />
					<div className="carla-box">
						<img src="/assets/carla.gif" alt="carla" />
					</div>
				</Paper>
			) : (
				<Loading />
			)}
		</div>
	);
}

function TextSpeakFirst({ speak, heading, next }) {
	useEffect(() => {
		async function init() {}
		init();
	}, []);
	return (
		<div>
			{heading && <div style={{ fontWeight: "bold" }}> {heading} </div>}
			<div>{speak}</div>
			{(MediaRecorder
				? MediaRecorder.isTypeSupported
					? !MediaRecorder.isTypeSupported("audio/webm")
					: true
				: true) && (
				<>
					<br></br>
					<div style={{ fontSize: "smaller", fontWeight: "bold" }}>
						Please upgrade your browser to the latest version or try
						Chrome, Firefox.
					</div>
				</>
			)}
			{
				<div className="initializer" id="initializer">
					<Button
						style={{ marginTop: "16px" }}
						color="secondary"
						variant="contained"
						onClick={() => {
							// const audioElement =
							// 	document.createElement("audio");
							// audioElement.setAttribute("id", "dummy");
							// audioElement.setAttribute("controls", false);
							// audioElement.setAttribute("style", "display:none");
							// document.body.appendChild(audioElement);
							// var dummy = document.getElementById("dummy");
							// dummy.play();
							// dummy.remove();

							async function play() {
								if (heading?.length > 0) {
									await playAudioFromBase64(heading);
								}
								await playAudioFromBase64(speak);
								next((n) => ++n);
							}
							play();

							document.getElementById(
								"initializer"
							).style.display = "none";
						}}
					>
						I&rsquo;m Ready
					</Button>
				</div>
			}
		</div>
	);
}

function TextSpeakWithNewName({ speak, heading, next, randomName }) {
	useEffect(() => {
		async function init() {
			await playAudioFromBase64(speak);
			next((n) => ++n);
		}
		init();
	}, []);

	return (
		<>
			<div className="dashboard-header">
				<div className="dashboard-display-name">
					<img src="/assets/new-name.gif"></img>
					{randomName}
				</div>
			</div>
			<div>
				{heading && (
					<div style={{ fontWeight: "bold" }}> {heading} </div>
				)}
				<div>{speak}</div>
			</div>
		</>
	);
}

function TextSpeak({ speak, heading, next, randomName }) {
	useEffect(() => {
		async function init() {
			if (heading?.length > 0) {
				await playAudioFromBase64(heading);
			}
			await playAudioFromBase64(speak);
			next((n) => ++n);
		}
		init();
	}, []);

	return (
		<>
			{randomName && (
				<div className="dashboard-header">
					<div className="dashboard-display-name">
						<img src="/assets/avatar.png"></img>
						{randomName}
					</div>
				</div>
			)}
			<div>
				{heading && (
					<div style={{ fontWeight: "bold" }}> {heading} </div>
				)}
				<div>{speak}</div>
			</div>
		</>
	);
}
function VideoPlay({ speak, next }) {
	const videoRef = useRef();
	useEffect(() => {
		videoRef.current.onended = function () {
			next((n) => ++n);
		};
	}, []);
	return (
		<div
			style={{
				position: "fixed",
				zIndex: "10",
				top: "0%",
				left: "0%",
				height: "100vh",
				width: "100vw",
				display: "grid",
				placeItems: "center",
				backgroundColor: "black",
			}}
		>
			<video
				style={{
					height: "100%",
					width: "100%",
				}}
				autoPlay
				ref={videoRef}
			>
				<source src="/assets/breathing.mp4" type="video/mp4" />
			</video>
			{/* TODO: only display if admin user
			<button
				style={{ position: "absolute", bottom: "0%" }}
				onClick={() => {
					next((n) => ++n);
				}}
			>
				Continue
			</button>
			*/}
		</div>
	);
}

function QuestionPlay({
	question,
	next,
	sessionId,
	setAnswers,
	randomName,
	startTime,
	setStartTime,
}) {
	useEffect(() => {
		playAudioFromBase64(question?.text);
		if (!startTime) {
			setStartTime(new Date());
		}
	}, []);
	const submitAnswers = (questionid, answerKey) => {
		const currentAnswer = {
			sessionId: sessionId,
			questionId: questionid,
			answerKey: answerKey,
		};

		setAnswers((cur) => {
			cur.push(currentAnswer);
			return cur;
		});

		next((n) => ++n);
	};

	return (
		<div style={{ display: "grid", placeItems: "center" }}>
			<div className="dashboard-header">
				<div className="dashboard-display-name">
					<img src="/assets/avatar.png"></img>
					{randomName}
				</div>
			</div>
			<div className="question-body-text">{question?.text}</div>
			<div className="question-body-image-container">
				{question?.images.map((image) => (
					<div
						className="question-body-image"
						key={image.key}
						onClick={() => submitAnswers(question?.id, image?.key)}
					>
						<img
							style={{
								height: "100%",
								width: "100%",
							}}
							src={`${process.env.REACT_APP_BASE_IMAGES_URL}${image.key}`}
							className="questions-image"
						/>
					</div>
				))}
			</div>
		</div>
	);
}
