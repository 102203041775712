import React, { useEffect, useState } from "react";

// import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import SignalCellularAltIcon from "@material-ui/icons/SignalCellularAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import { createClient } from "agora-rtc-react";
import AgoraRTC from "agora-rtc-sdk-ng";
import axios from "axios";
import { useHistory } from "react-router-dom";
import * as Tone from "tone";
import { v4 as uuid } from "uuid";

import { serverLog } from "../../App";
import { useSession } from "../../context/SessionContext";
import {
	vibratoVoiceFilter,
	deepVoiceFilter,
	heliumVoiceFilter,
	chipmunkVoiceFilter,
} from "../../voiceFilters/voiceFilter.js";
import MicDeviceSelection from "../MicDeviceSelection/MicDeviceSelection";
import ModulePlayer from "../ModulePlayer/ModulePlayer.jsx";
import "./RoomPage.css";

// const RedButton = withStyles({
// 	root: {
// 		borderColor: "red",
// 		color: "red",
// 	},
// })(Button);

const useClient = createClient({ mode: "rtc", codec: "vp8" });
const RoomPage = (props) => {
	const [filter, setFilter] = useState("Helium");
	const [channelName, setChannelName] = useState(null);
	const [isJoin, setIsJoin] = useState(false);
	const [isMuted, setIsMuted] = useState(false);
	const [selectOpen, setSelectOpen] = useState(false);
	const [isCarlaSpeaking, setIsCarlaSpeaking] = useState(false);
	const [selectedDevice, setSelectedDevice] = useState(
		localStorage.getItem("audio-device")
	);
	const { randomName, sessionId, matchedUser, updateMatchedUserName } =
		useSession();
	const history = useHistory();
	const client = useClient();
	if (process.env.NODE_ENV !== 'test') {
		AgoraRTC.setLogLevel(1);
	}

	var token = localStorage.getItem("access-token");

	const [localAudioTrack, setLocalAudioTrack] = useState(null);
	const doRecord = false;
	let rtc = {
		localAudioTrack: null,
	};

	let options = {
		appId: `${process.env.REACT_APP_AGORA_APP_ID}`,
		uid: uuid(),
	};

	const onUnload = (e) => {
		// the method that will be used for both add and remove event
		e.preventDefault();
		e.returnValue = "asds";
	};

	useEffect(() => {
		const startCall = async () => {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/getmatch`,
					{
						sessionId,
					},
					{
						headers: {
							Authorization: token,
						},
					}
				);
				console.log(response.data);
				if (response.data.success) {
					updateMatchedUserName({ names: response.data.data });
				} else if (!response.data.success) {
					console.log(response.data.msg);
				}
			} catch (err) {
				console.log(err);
			}

			client.on("user-published", async (user, mediaType) => {
				// Subscribe to the remote user when the SDK triggers the "user-published" event
				await client.subscribe(user, mediaType);

				console.log("subscribe success");

				// If the remote user publishes an audio track.
				if (mediaType === "audio") {
					// Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
					const remoteAudioTrack = user.audioTrack;
					// Play the remote audio track.
					remoteAudioTrack.play();
					console.log("playing audio");
				}

				// Listen for the "user-unpublished" event
				client.on("user-unpublished", async (user) => {
					// Unsubscribe from the tracks of the remote user.

					await client.unsubscribe(user);

					console.log("unsubscribed");
				});
			});
		};

		startCall();
	}, [client]);

	useEffect(() => {
		window.addEventListener("beforeunload", onUnload);
		let room = props.match.params.roomId;
		setChannelName(room);

		joinCall(room);

		return () => {
			window.removeEventListener("beforeunload", onUnload);
		};
	}, []);

	const publishAudio = async (filterx) => {
		if (localAudioTrack) {
			localAudioTrack.close();
		}

		var micStream = await navigator.mediaDevices.getUserMedia({
			audio: { deviceId: selectedDevice || null },
		});
		//
		// This only works for chipmunkVoiceFilter right now…
		//
		/*
		var ctx = Tone.context;
		var destination = Tone.context.createMediaStreamDestination();
		var mic = new Tone.UserMedia();
		*/

		var ctx = new AudioContext();
		var mic = ctx.createMediaStreamSource(micStream);
		var destination = ctx.createMediaStreamDestination();

		// console.log({ filter: filterx });
		switch (filterx) {
			case "None":
				mic.connect(destination);
				break;
			case "Deep":
				deepVoiceFilter({ ctx, mic, destination });
				break;
			case "Helium":
				heliumVoiceFilter({ ctx, mic, destination });
				break;
			case "Vibrato":
				vibratoVoiceFilter({ ctx, mic, destination });
				break;
			/*			case "Chipmunk":
				mic.open(micStream.id);
				chipmunkVoiceFilter({ ctx, mic, destination });
				break;
*/
			default:
				mic.connect(destination);
				break;
		}

		let localTrack = await AgoraRTC.createCustomAudioTrack({
			mediaStreamTrack: destination.stream.getAudioTracks()[0],
		});

		rtc.localAudioTrack = localTrack;
		// console.log(rtc.localAudioTrack);

		await client.publish([rtc.localAudioTrack]);

		setLocalAudioTrack(rtc.localAudioTrack);
	};

	const joinCall = async (channel) => {
		const token = localStorage.getItem("access-token");
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/gettoken`,
			{
				channel,
			},
			{
				headers: {
					Authorization: token,
				},
			}
		);
		const tokenAgora = res.data;
		
		await client.join(options.appId, channel, tokenAgora, options.uid);
		console.log("joining...");
		await publishAudio(filter);
		setIsJoin((old) => (old = !old));

		if (!process.env.REACT_APP_DISABLE_RECORDING) {
			axios
				.post(
					`${process.env.REACT_APP_BASE_SERVER_URL}/api/room/startrecording`,
					{
						sessionId,
						roomId: channel,
						token: tokenAgora,
					},
					{
						headers: {
							Authorization: token,
						},
					}
				)
				.then((res) => {
					console.log({ recStatus: res });
				})
				.catch(function (err) {
					console.log(err);
				});
			localStorage.setItem("roomId", channel);
		}
	};

	const leaveCall = async () => {
		localAudioTrack?.close();
		await client.leave();
		console.log("leaving");
		history.push("/room-feedback");
	};

	const handleChange = async (e) => {
		await client.unpublish();
		setFilter(e.target.value);
		publishAudio(e.target.value, selectedDevice);

		serverLog(sessionId, "room_change_filter", `Changed to ${e.target.value}`);
	};

	return (
		<div className="roompage-container">
			{
				<video
					loop
					muted
					disablePictureInPicture
					autoPlay
					id="background-stars"
				>
					<source
						src="/assets/background-stars.mp4"
						type="video/mp4"
					/>
				</video>
			}

			<div className="room-header">
				<div className="room-display-name">
					<img src="/assets/avatar.png"></img>
					{randomName}
				</div>
				<div className="room-other-user room-display-name">
					<img src="/assets/avatar.png"></img>
					{matchedUser}
				</div>
			</div>
			<div className="room-box">
				<div className="call-controls">
					<h3>
						<b>Room: </b>
						{channelName}
					</h3>
					<div className="options">
						<Select
							disabled={isMuted}
							variant="outlined"
							value={filter}
							onChange={(e) => handleChange(e)}
						>
							<MenuItem value={"None"}><em>None</em></MenuItem>
							<MenuItem value={"Helium"}>Helium</MenuItem>
							<MenuItem value={"Deep"}>Deep</MenuItem>
							<MenuItem value={"Vibrato"}>Wobbly</MenuItem>
						</Select>
					</div>
					<div className="buttons">
						{/* <RedButton
							variant="outlined"
							disabled={!isJoin}
							onClick={() => {
								leaveCall();
							}}
						>
							Back to lobby
						</RedButton> */}
					</div>
					<div>
						{isJoin && (
							<div className="voice-connected-div">
								<SignalCellularAltIcon
									style={{ color: "green" }}
								/>
								<Typography>
									{isMuted ? "Muted" : "Voice Connected"}
								</Typography>
							</div>
						)}
					</div>
				</div>

				<ModulePlayer
					leaveCall={leaveCall}
					client={client}
					setIsMuted={setIsMuted}
				/>
			</div>
			<div
				style={{
					color: "white",
					position: "fixed",
					right: "0px",
					top: "0px",
					zIndex: "1000",
				}}
				className="mic-change"
				onClick={() => setSelectOpen(true)}
			>
				<SettingsIcon />
			</div>
			<MicDeviceSelection
				open={selectOpen}
				closeDialog={() => {
					setSelectOpen(false);
					let element = { target: { value: filter } };
					handleChange(element);
				}}
				selectedDevice={selectedDevice}
				setSelectedDevice={setSelectedDevice}
			></MicDeviceSelection>
		</div>
	);
};

export default RoomPage;
